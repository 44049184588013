<template>
  <div class="wrapper login-wrapper">
    <div
      class="container-wide flex-col"
      :class="
        $store.state.auth.loggedIn &&
        !$store.state.userData.user.is_email_verified
          ? 'mt-12'
          : ''
      "
    >
      <div
        class="flex flex-col w-7/12 mobile:w-full mx-auto mt-12 register-container p-12 mobile:p-6 mb-8 relative"
        v-if="!$store.state.auth.status.loggedIn"
      >
        <registration-form-worker-join></registration-form-worker-join>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationFormWorkerJoin from "@/components/registration/RegistrationFormWorkerJoin";
export default {
  name: "RegisterWorkerWithToken",
  data() {
    return {
      asProvider: false,
      typeSelected: false
    };
  },
  components: {
    RegistrationFormWorkerJoin
  },
  methods: {
    handleTypeSelection(e) {
      this.asProvider = e;
      this.typeSelected = true;
    }
  },
  mounted() {
    if (this.$route.query.job) {
      this.typeSelected = true;
      this.asProvider = true;
    }
  },
  computed: {
    headerText() {
      if (!this.typeSelected) {
        return "Registreeri konto";
      } else if (this.typeSelected) {
        if (this.asProvider) {
          return "Registreeru teenusepakkujana";
        } else {
          return "Registreeru tellijana";
        }
      } else {
        return "Registreeri konto";
      }
    }
  }
};
</script>

<style lang="scss">
.register-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.register-cancel-button {
  position: absolute;
  top: 9%;
  left: 6%;
}
</style>
